import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import { useSocket } from "../App";
import { constants } from "../utils/ranges";

import { ReactComponent as MaleIcon } from "../assets/icons/Male.svg";
import { ReactComponent as FemaleIcon } from "../assets/icons/Female.svg";
import { ReactComponent as CheckFilled } from "../assets/icons/CheckFilled.svg";
import { ReactComponent as HumanShort } from "../assets/icons/HumanShort.svg";
import { ReactComponent as HumanTall } from "../assets/icons/HumanTall.svg";
import { ReactComponent as HumanSlim } from "../assets/icons/HumanSlim.svg";
import { ReactComponent as HumanFat } from "../assets/icons/HumanFat.svg";

const languages = {
  en: { nativeName: "English", englishName: "English" },
  ar: { nativeName: "العربية", englishName: "Arabic" },
  ur: { nativeName: "اردو", englishName: "Urdu" },
  es: { nativeName: "español", englishName: "Spanish" },
  hi: { nativeName: "हिन्दी", englishName: "Hindi" },
  ml: { nativeName: "മലയാളം", englishName: "Malayalam" },
};

const cmToFeetAndInches = (cm) => {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const remainingInches = Math.round(totalInches % 12);
  return `${feet} ft ${remainingInches} in`;
};
const kgToLb = (kg) => `${Math.round(kg * 2.20462)} lb`;
const calculateBMI = (heightCm, weightKg) => {
  const heightM = heightCm / 100;
  const bmi = weightKg / heightM ** 2;
  return bmi.toFixed(2);
};

const SelectOptions = () => {
  const { t, i18n } = useTranslation("SelectOptions");
  const navigate = useNavigate();
  const socket = useSocket();

  const [page, setPage] = useState(1);

  const [openMenu, setOpenMenu] = useState(false);
  const [hasConsent, setConsent] = useState(false);

  const changeLanguageTo = (lang) => {
    i18n.changeLanguage(lang);
    socket?.emit("changeLanguageTo", lang);
  };

  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");

  const [height, setHeight] = useState(174);
  const [weight, setWeight] = useState(62);
  const [heightMetric, setHeightMetric] = useState("cm");
  const [weightMetric, setWeightMetric] = useState("kg");
  const heightBubbleTextPos = useMemo(
    () => ((height - 50) * 100) / (250 - 50),
    [height]
  );
  const weightBubbleTextPos = useMemo(
    () => ((weight - 20) * 100) / (220 - 20),
    [weight]
  );
  const bmi = useMemo(() => {
    const calculatedBMI = calculateBMI(height, weight);
    const selectedBMIRangeIndex = constants.BMI.ranges.reduce(
      (p, c) => (calculatedBMI > c.point ? c : p),
      0
    );
    return { value: calculatedBMI, range: selectedBMIRangeIndex.label };
  }, [height, weight]);

  const next = () => {
    switch (page) {
      case 1:
        if (hasConsent) {
          setPage(2);
          socket?.emit("playAudio", "fill_details");
        }
        break;
      case 2:
        if ((gender === "Male" || gender === "Female") && dob.length > 0)
          setPage(3);
        break;
      case 3:
        navigate("/start-scan", {
          state: { hasConsent, gender, dob, height, weight },
        });
        break;
      default:
        break;
    }
  };

  const back = () => {
    if (page === 1) navigate(-1);
    else setPage((p) => p - 1);
  };

  const cancel = () => {
    setPage(1);
    setOpenMenu(false);
    setConsent(false);
    setGender("");
    setDOB("");
    setHeight(174);
    setWeight(62);
    setHeightMetric("cm");
    setWeightMetric("kg");
  };

  useEffect(() => {
    socket?.emit("changeLanguageTo", i18n.resolvedLanguage);
    socket?.emit("playAudio", "welcome");
  }, [i18n.resolvedLanguage, socket]);

  return (
    <section className="shrink-0 grow px-[15%]">
      {page === 1 ? (
        <>
          <Typography variant="h4" className="mb-4 text-secondary">
            {t("SelectLanguage")}
          </Typography>
          <Menu open={openMenu} handler={setOpenMenu}>
            <MenuHandler>
              <Button
                variant="text"
                className="w-full flex items-center justify-between gap-3 rounded-xl border border-stroke bg-white text-primary"
              >
                <span className="grow text-left rtl:text-right text-2xl font-normal normal-case">
                  {languages[i18n.resolvedLanguage].nativeName}
                </span>
                <svg
                  className={`h-6 w-6 transition-transform ${
                    openMenu ? "rotate-180" : ""
                  }`}
                  viewBox="0 0 41 22"
                  fill="none"
                >
                  <path
                    d="M2 2L20.5 20L39 2"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </MenuHandler>
            <MenuList className="w-2/3">
              {Object.keys(languages).map((lng) => (
                <MenuItem
                  key={lng}
                  className={`${
                    i18n.resolvedLanguage === lng
                      ? "bg-secondary text-white focus:bg-secondary focus:text-white"
                      : "bg-white text-primary focus:bg-transparent focus:text-primary"
                  } text-2xl font-normal active:bg-secondary active:text-white`}
                  onClick={() => changeLanguageTo(lng)}
                >
                  {languages[lng].nativeName}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <div className="mt-96">
            <Checkbox
              id="consent"
              color="red"
              label={t("consent")}
              labelProps={{ className: "text-primary" }}
              containerProps={{ className: "shrink-0" }}
              className="border border-stroke bg-white"
              ripple={true}
              value={hasConsent}
              checked={hasConsent}
              onChange={(e) => setConsent(e.target.checked)}
            />
          </div>
        </>
      ) : page === 2 ? (
        <>
          <Typography variant="h4" className="mb-8 text-secondary">
            {t("SelectGender")}
          </Typography>
          <div className="mb-24 flex items-stretch gap-12">
            {[
              { label: "Male", Icon: MaleIcon },
              { label: "Female", Icon: FemaleIcon },
            ].map(({ label, Icon }) => (
              <label
                key={`gender-${label}`}
                className={`flex flex-col items-center justify-between gap-4 text-primary`}
              >
                <input
                  className="sr-only"
                  type="radio"
                  name="gender"
                  checked={gender === label}
                  value={label}
                  onChange={(e) => setGender(e.target.value)}
                />
                <div
                  className={`shrink-0 relative h-32 w-32 p-8 rounded-2xl border border-stroke ${
                    gender === label ? "bg-stroke/[0.15]" : ""
                  }`}
                >
                  <Icon className="h-full w-full" />
                  {gender === label && (
                    <CheckFilled className="absolute top-4 right-4 h-6 w-6 text-secondary" />
                  )}
                </div>
                <span className="text-lg">{t(label)}</span>
              </label>
            ))}
          </div>
          <Typography variant="h4" className="mb-8 text-secondary">
            {t("SelectDOB")}
          </Typography>
          <label className="block relative mb-24 w-full p-4 rounded-lg overflow-hidden border border-stroke bg-white text-lg text-primary">
            <input
              type="date"
              id="dob"
              name="dob"
              value={dob}
              min={
                new Date(new Date().setFullYear(new Date().getFullYear() - 110))
                  .toISOString()
                  .split("T")[0]
              }
              max={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
                  .toISOString()
                  .split("T")[0]
              }
              onChange={(e) => setDOB(e.target.value)}
            />
            <p className="text-lg text-primary pr-16">
              {dob.length > 0
                ? new Date(dob).toLocaleDateString(i18n.resolvedLanguage, {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                : t("DOB")}
            </p>
            <svg
              className="absolute inset-y-0 right-0 h-[60px] w-[60px] p-4 bg-white text-primary/70"
              viewBox="0 0 53 53"
              fill="none"
            >
              <path
                d="M45.05 5.3H39.75V2.65C39.75 1.94718 39.4708 1.27314 38.9738 0.776167C38.4769 0.279195 37.8028 0 37.1 0C36.3972 0 35.7231 0.279195 35.2262 0.776167C34.7292 1.27314 34.45 1.94718 34.45 2.65V5.3H18.55V2.65C18.55 1.94718 18.2708 1.27314 17.7738 0.776167C17.2769 0.279195 16.6028 0 15.9 0C15.1972 0 14.5231 0.279195 14.0262 0.776167C13.5292 1.27314 13.25 1.94718 13.25 2.65V5.3H7.95C5.84153 5.3 3.81942 6.13759 2.3285 7.6285C0.837587 9.11942 0 11.1415 0 13.25V45.05C0 47.1585 0.837587 49.1806 2.3285 50.6715C3.81942 52.1624 5.84153 53 7.95 53H45.05C47.1585 53 49.1806 52.1624 50.6715 50.6715C52.1624 49.1806 53 47.1585 53 45.05V13.25C53 11.1415 52.1624 9.11942 50.6715 7.6285C49.1806 6.13759 47.1585 5.3 45.05 5.3ZM47.7 45.05C47.7 45.7528 47.4208 46.4269 46.9238 46.9238C46.4269 47.4208 45.7528 47.7 45.05 47.7H7.95C7.24718 47.7 6.57314 47.4208 6.07617 46.9238C5.5792 46.4269 5.3 45.7528 5.3 45.05V26.5H47.7V45.05ZM47.7 21.2H5.3V13.25C5.3 12.5472 5.5792 11.8731 6.07617 11.3762C6.57314 10.8792 7.24718 10.6 7.95 10.6H13.25V13.25C13.25 13.9528 13.5292 14.6269 14.0262 15.1238C14.5231 15.6208 15.1972 15.9 15.9 15.9C16.6028 15.9 17.2769 15.6208 17.7738 15.1238C18.2708 14.6269 18.55 13.9528 18.55 13.25V10.6H34.45V13.25C34.45 13.9528 34.7292 14.6269 35.2262 15.1238C35.7231 15.6208 36.3972 15.9 37.1 15.9C37.8028 15.9 38.4769 15.6208 38.9738 15.1238C39.4708 14.6269 39.75 13.9528 39.75 13.25V10.6H45.05C45.7528 10.6 46.4269 10.8792 46.9238 11.3762C47.4208 11.8731 47.7 12.5472 47.7 13.25V21.2Z"
                fill="currentColor"
              />
            </svg>
          </label>
        </>
      ) : (
        <>
          <div className="mb-8">
            <div className="flex items-start justify-between">
              <Typography variant="h4" className="text-secondary">
                {t("YourHeight")}
              </Typography>
              <div dir="ltr" className="shrink-0 grid grid-cols-2 gap-2.5">
                {["cm", "ft"].map((metric) => (
                  <label
                    key={metric}
                    className="px-3 py-1.5 w-24 flex items-center justify-between gap-2.5 rounded-md border border-stroke bg-white text-primary"
                  >
                    <input
                      className="sr-only"
                      type="radio"
                      name="height-metric"
                      checked={heightMetric === metric}
                      value={metric}
                      onChange={(e) => setHeightMetric(e.target.value)}
                    />
                    <span>{metric}</span>
                    {heightMetric === metric && (
                      <svg
                        className="shrink-0 w-4 h-4"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div className="mt-4 flex items-end justify-between">
              <HumanShort className="shrink-0 h-10" />
              <HumanTall className="shrink-0 h-20" />
            </div>
            <div className="relative text-primary">
              <input
                type="range"
                className="w-full"
                min={50}
                max={250}
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
              <output
                dir="ltr"
                className="absolute -top-10 -translate-x-1/2 px-2 py-1 rounded-lg bg-secondary text-white text-lg font-semibold tracking-wider whitespace-nowrap"
                style={{
                  left: `calc(${
                    i18n.dir() === "ltr"
                      ? heightBubbleTextPos + "% + "
                      : 100 - heightBubbleTextPos + "% - "
                  }(${8 - heightBubbleTextPos * 0.15}px))`,
                }}
              >
                {heightMetric === "cm"
                  ? `${height} cm`
                  : cmToFeetAndInches(height)}
              </output>
            </div>
            <div className="flex items-start justify-between text-primary">
              <p dir="ltr">
                {heightMetric === "cm" ? "50 cm" : cmToFeetAndInches(50)}
              </p>
              <p dir="ltr">
                {heightMetric === "cm" ? "250 cm" : cmToFeetAndInches(250)}
              </p>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-start justify-between">
              <Typography variant="h4" className="text-secondary">
                {t("YourWeight")}
              </Typography>
              <div dir="ltr" className="shrink-0 grid grid-cols-2 gap-2.5">
                {["kg", "lb"].map((metric) => (
                  <label
                    key={metric}
                    className="px-3 py-1.5 w-24 flex items-center justify-between gap-2.5 rounded-md border border-stroke bg-white text-primary"
                  >
                    <input
                      className="sr-only"
                      type="radio"
                      name="weight-metric"
                      checked={weightMetric === metric}
                      value={metric}
                      onChange={(e) => setWeightMetric(e.target.value)}
                    />
                    <span>{metric}</span>
                    {weightMetric === metric && (
                      <svg
                        className="shrink-0 w-4 h-4"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </label>
                ))}
              </div>
            </div>
            <div className="mt-4 flex items-end justify-between">
              <HumanSlim className="shrink-0 h-20" />
              <HumanFat className="shrink-0 h-20" />
            </div>
            <div className="relative text-primary">
              <input
                type="range"
                className="w-full"
                min={20}
                max={220}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
              <output
                dir="ltr"
                className="absolute -top-10 -translate-x-1/2 px-2 py-1 rounded-lg bg-secondary text-white text-lg font-semibold tracking-wider whitespace-nowrap"
                style={{
                  left: `calc(${
                    i18n.dir() === "ltr"
                      ? weightBubbleTextPos + "% + "
                      : 100 - weightBubbleTextPos + "% - "
                  }(${8 - weightBubbleTextPos * 0.15}px))`,
                }}
              >
                {weightMetric === "kg" ? `${weight} kg` : kgToLb(weight)}
              </output>
            </div>
            <div className="flex items-start justify-between text-primary">
              <p dir="ltr">{weightMetric === "kg" ? "20 kg" : kgToLb(20)}</p>
              <p dir="ltr">{weightMetric === "kg" ? "220 kg" : kgToLb(220)}</p>
            </div>
          </div>
          <div className="py-4 px-6 flex items-start justify-between gap-4 rounded-2xl border border-stroke bg-stroke/[0.15]">
            <Typography
              variant="h4"
              className="text-primary text-xl font-medium"
            >
              {t("YourBMI")}
            </Typography>
            <div className="shrink-0 flex flex-col items-end gap-2">
              <Typography
                variant="h4"
                className="text-primary text-xl font-medium"
              >
                {`BMI = ${bmi.value} kg/m²`}
              </Typography>
              <Typography
                variant="h5"
                className="text-black text-lg font-medium"
              >
                {t(bmi.range)}
              </Typography>
            </div>
          </div>
        </>
      )}

      <div className="mt-8 mb-4 flex items-stretch justify-between gap-8">
        <Button
          color="white"
          className="flex-1 bg-white text-primary/80 border border-stroke text-lg normal-case"
          onClick={back}
        >
          {t("Back")}
        </Button>
        <Button
          color="white"
          className="flex-1 bg-primary text-white border border-primary text-lg normal-case"
          onClick={next}
          disabled={!hasConsent}
        >
          {t("Next")}
        </Button>
      </div>
      {page !== 1 && (
        <Button
          color="white"
          className="my-4 w-full bg-white text-primary/80 border border-stroke text-lg font-medium normal-case"
          onClick={cancel}
        >
          {t("Cancel")}
        </Button>
      )}
    </section>
  );
};

export default SelectOptions;
