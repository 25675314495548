import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-tailwind/react";

import { useSocket } from "../App";
import { sdkWrapperURL } from "../utils/api-url-list";

import { ReactComponent as FaceMesh } from "../assets/icons/FaceMesh.svg";

const StartScan = () => {
  const { t } = useTranslation("StartScan");
  const navigate = useNavigate();
  const socket = useSocket();
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    socket?.emit("reset");
    socket?.emit("playAudio", "start_face_scan");
  }, [socket]);

  const start = async () => {
    setLoading(true);
    try {
      const scanTokenResp = await fetch(
        sdkWrapperURL("/users/sdk/test_api_private"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth_token"),
          },
          body: JSON.stringify({
            action: "create_scan_UL",
            employee_id: localStorage.getItem("auth_id"),
          }),
        }
      );
      const scanTokenRespJSON = await scanTokenResp.json();
      if (scanTokenRespJSON?.statusCode?.toString().startsWith("2"))
        navigate("/scan", {
          state: { ...state, token: scanTokenRespJSON.scan_token },
        });
      else
        throw new Error(
          scanTokenRespJSON?.message ?? "Error in Fetching the Scan Token"
        );
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="shrink-0 grow px-[12%]">
      <Typography variant="h4" className="text-primary text-center">
        {t("ScanHeading")}
      </Typography>
      <FaceMesh className="my-10 mx-auto p-4 h-56 w-56 rounded-xl shadow-lg bg-stroke/30 text-primary" />
      <div className="my-8">
        {[...Array(4).keys()].map((idx) => (
          <div
            key={`scan-instruction-${idx}`}
            className="mb-4 flex items-start justify-start gap-3 text-primary text-justify"
          >
            <svg className="shrink-0 h-8 w-8" viewBox="0 0 60 60" fill="none">
              <path
                d="M26.25 38.1244C25.7675 38.0876 25.319 37.8631 25 37.4994L17.5 29.9994C17.3329 29.6496 17.2783 29.2566 17.3439 28.8744C17.4095 28.4921 17.5919 28.1399 17.8661 27.8656C18.1403 27.5914 18.4927 27.4089 18.8748 27.3434C19.257 27.2779 19.6501 27.3324 20 27.4994L26.175 33.6744L47.5 12.4995C47.8498 12.3324 48.243 12.2778 48.625 12.3434C49.0073 12.409 49.3598 12.5914 49.6338 12.8656C49.908 13.1398 50.0905 13.4922 50.156 13.8743C50.2215 14.2565 50.167 14.6496 50 14.9995L27.5 37.4994C27.181 37.8631 26.7323 38.0876 26.25 38.1244Z"
                fill="currentColor"
              />
              <path
                d="M30.0001 52.4981C25.9788 52.4916 22.0326 51.4076 18.5723 49.3588C15.1121 47.3098 12.2642 44.3711 10.325 40.8481C8.85098 38.2231 7.93995 35.3198 7.65003 32.3231C7.19248 27.9283 8.03905 23.4961 10.0841 19.5793C12.1292 15.6625 15.2823 12.4346 19.15 10.2982C21.7751 8.8241 24.6784 7.91308 27.6751 7.62315C30.6603 7.302 33.6798 7.5912 36.5501 8.47315C36.8061 8.52433 37.0488 8.62845 37.2623 8.77888C37.4758 8.92928 37.6556 9.12265 37.7901 9.3466C37.9246 9.57053 38.0108 9.8201 38.0433 10.0793C38.0756 10.3385 38.0536 10.6016 37.9783 10.8517C37.9031 11.1019 37.7763 11.3336 37.6063 11.5319C37.4363 11.7302 37.2268 11.8909 36.9913 12.0035C36.7556 12.1162 36.4988 12.1783 36.2378 12.186C35.9768 12.1937 35.7168 12.1467 35.475 12.0482C33.0466 11.318 30.4968 11.08 27.975 11.3482C25.4825 11.6034 23.0674 12.3602 20.875 13.5732C18.7629 14.7384 16.8954 16.3003 15.375 18.1732C13.8097 20.0811 12.6408 22.2821 11.9367 24.6474C11.2327 27.0128 11.0078 29.4948 11.275 31.9481C11.5303 34.4406 12.2871 36.8558 13.5 39.0481C14.6653 41.1603 16.2272 43.0278 18.1 44.5481C20.0079 46.1136 22.209 47.2823 24.5743 47.9863C26.9396 48.6903 29.4216 48.9153 31.8751 48.6481C34.3676 48.3928 36.7826 47.6361 38.9751 46.4231C41.0873 45.2578 42.9546 43.6961 44.4751 41.8231C46.0403 39.9153 47.2093 37.7141 47.9133 35.3488C48.6173 32.9836 48.8423 30.5016 48.5751 28.0481C48.5503 27.7903 48.5768 27.5303 48.6526 27.2828C48.7286 27.0353 48.8523 26.8051 49.0173 26.6056C49.182 26.4058 49.3846 26.2406 49.6133 26.1193C49.8421 25.9978 50.0923 25.9228 50.3501 25.8981C50.6078 25.8736 50.8678 25.8998 51.1153 25.9758C51.3628 26.0516 51.593 26.1756 51.7925 26.3403C51.9923 26.5051 52.1576 26.7078 52.2788 26.9363C52.4003 27.1651 52.4753 27.4153 52.5001 27.6731C52.9553 32.0706 52.1051 36.5046 50.0553 40.4218C48.0056 44.3388 44.8473 47.5653 40.9751 49.6981C38.3221 51.2326 35.3738 52.1868 32.3251 52.4981C31.5501 52.4981 30.7501 52.4981 30.0001 52.4981Z"
                fill="currentColor"
              />
            </svg>
            <p className="grow text-lg leading-snug">
              {t(`scan_instruction_${idx}`)}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-8 mb-4 flex flex-col items-stretch justify-between gap-8">
        <Button
          color="white"
          className="flex-1 bg-primary text-white border border-primary text-lg normal-case"
          onClick={start}
          disabled={!state?.hasConsent || isLoading}
        >
          {t("StartScan")}
        </Button>
        <Button
          color="white"
          className="flex-1 bg-white text-primary/80 border border-stroke text-lg normal-case"
          onClick={() => navigate(-1)}
          disabled={isLoading}
        >
          {t("Back")}
        </Button>
      </div>
    </section>
  );
};

export default StartScan;
