import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Carousel, Typography } from "@material-tailwind/react";

import { useSocket } from "../App";

import GetStartedBG from "../assets/images/GetStartedBG.jpg";
import { ReactComponent as EEnterpriseLogo } from "../assets/logos/e&enterprise.svg";
import CarePlixLogo from "../assets/logos/CarePlix.png";

const GetStarted = () => {
  const navigate = useNavigate();
  const socket = useSocket();

  useEffect(() => {
    socket?.emit("reset");
  }, [socket]);

  return (
    <section
      dir="ltr"
      className="grow shrink-0 bg-no-repeat bg-cover bg-center flex flex-col items-center text-white"
      style={{ backgroundImage: `url(${GetStartedBG})` }}
    >
      <EEnterpriseLogo className="m-12 w-2/5 max-w-[350px]" />
      <Carousel
        prevArrow={() => <></>}
        nextArrow={() => <></>}
        navigation={({ activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 -translate-x-2/4 z-50 flex items-stretch justify-between gap-4">
            {[...Array(length).keys()].map((i) => (
              <span
                key={`carousel-nav-${i}`}
                className={`shrink-0 h-4 w-4 rounded-full ${
                  activeIndex === i ? "bg-secondary" : "bg-white"
                }`}
              />
            ))}
          </div>
        )}
        autoplay
        autoplayDelay={3000}
        loop
        className="self-stretch grow items-center text-center whitespace-pre-line"
      >
        {[
          {
            title: "Futuristic\nHealth Monitoring",
            subtitle: "AI-Powered Face Scan\nfor Vital Insights",
          },
          {
            title: "60 Seconds to a\nHealthier You",
            subtitle: "Unlock Your Wellness\nPotential Now",
          },
        ].map((item, index) => (
          <Fragment key={`carousel-item-${index}`}>
            <Typography
              variant="h1"
              className="text-6xl leading-tight font-semibold"
            >
              {item.title}
            </Typography>
            <Typography
              variant="h2"
              className="mt-5 text-5xl leading-tight font-normal"
            >
              {item.subtitle}
            </Typography>
          </Fragment>
        ))}
      </Carousel>
      <Button
        size="lg"
        color="white"
        className="shrink-0 mt-12 w-2/5 text-2xl text-secondary font-semibold normal-case"
        onClick={() => navigate("/select-options")}
      >
        Get Started
      </Button>
      <img
        className="shrink-0 my-20 h-16"
        src={CarePlixLogo}
        alt="Powered by CarePlix"
      />
    </section>
  );
};

export default GetStarted;
